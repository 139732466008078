import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "./jobs.yml"
import { Cursor, CursorStep } from "../components/cursor"

const CursorInit = startH => {
  startH.init()
}

const JobsPage = () => {
  
  setTimeout(() => {
    
    document
    .querySelector("#menu")
    .querySelectorAll("li").forEach(li => {
      li.classList.remove('current')
    })
    
    document
    .querySelector("#menu")
    .querySelectorAll("li")[4]
    .classList.add("current");
  }, 2000)

  return (
    <Layout>
      <SEO title={Content.title} />

      <div className="content jobs">
        <Cursor cursorReady={CursorInit}>
          {Content.content.map(({ step }, index) => {
            return (
              <CursorStep
                key={`cursor-step-${index}`}
                index={index}
                content={step}
              />
            )
          })}
        </Cursor>
      </div>
    </Layout>
  )
}

export default JobsPage
